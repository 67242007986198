<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 分类管理
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container category-wrapper">
            <el-button type="primary" @click="_onAddCategoryClick">添加分类</el-button>
            <el-tabs v-model="editableTabsValue" type="card">
                <el-tab-pane
                        v-for="(item) in categories"
                        :key="item.id"
                        :label="item.categoryName"
                        :name="item.id"
                >
                    <el-card class="box-card" v-for="child in item.children" :key="child.id">
                        <div class="clearfix title">
                            <span>{{child.categoryName}}</span>
                        </div>
                        <div class="flex-row">
                            <el-image
                                    fit="cover"
                                    :src="child.descPic"
                                    :preview-src-list="[child.descPic]"
                            >
                            </el-image>
                            <div class="description">{{child.description}}
                            </div>
                        </div>
                    </el-card>

                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="添加新属性" v-model="dialogFormVisible" width="50%">
            <el-form ref="form" :model="form" label-width="120px">
                <el-form-item label="名称">
                    <el-input v-model="category.categoryName"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="category.description"></el-input>
                </el-form-item>
                <el-form-item label="分类封面" :rules="[{ required: true, message: '分类封面不能为空'}]">
                    <el-upload
                            class="avatar-uploader"
                            :action="imageUploadUrl"
                            :show-file-list="false"
                            name="file"
                            :on-success="handleAvatarSuccess"
                    >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="上级分类">
                    <el-select v-model="category.parentId" placeholder="请选择上级分类">
                        <el-option :label="item.categoryName" v-for="item in topCategories" :key="item.id"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="_addNewCategory">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>

</template>

<script>
    import api from '../api/api'
    import {IMAGE_UPLOAD_URL} from "../api/api";
    import {ElMessage} from 'element-plus';

    export default {
        name: "CategoryList",
        data() {
            return {
                url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                editableTabsValue: 1,
                imageUrl: '',
                categories: [],
                category: {},
                topCategories: [],
                editVisible: false,
                activeNames: 1,
                dialogFormVisible: false,
            };
        },
        created() {
            this._getClothingCategories();
            this.imageUploadUrl = IMAGE_UPLOAD_URL;
        },
        methods: {
            /**
             *
             * 上传图片成功回调
             */
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                if (res.code === 0) {
                    let data = res.data;
                    this.category.descPic = data.fullPath;
                } else {
                    ElMessage({type: 'error', message: res.message})
                }
            },
            _onAddCategoryClick() {
                this.dialogFormVisible = true;
                console.log("_onAddCategoryClick", this.dialogFormVisible)
            },
            /**
             * 添加新的服装分类
             */
            _addNewCategory() {
                console.log(this.category)
                api.post("/category", this.category, {})
                    .then(res => {
                        console.log(res);
                        ElMessage({type: 'success', message: '添加成功'})
                        this._getClothingCategories();
                        this.dialogFormVisible = false;
                    }).catch(err => {
                    console.log(err);
                })
            },
            /**
             * 获取服装分类信息
             * @private
             */
            _getClothingCategories() {
                api.get("/categories?size=50")
                    .then(res => {
                        if (res.code === 0) {
                            let data = res.data;
                            if (data.total === 0)
                                return;
                            this.categories = this._getTrees(data.records);
                            this.topCategories = this._getTopCategories(data.records);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        ElMessage({type: 'error', message: '网络问题'})
                    });
            },
            /**
             *  获取顶层分类
             */
            _getTopCategories(data, targerId = 0) {
                let targetArr = [];
                data.forEach(item => {
                    if (item.parentId === targerId) {
                        targetArr.push(item);
                    }
                });
                return targetArr;
            },
            /**
             * 递归处理分类树结构
             * @param list
             * @param parentId
             * @returns {*}
             * @private
             */
            _getTrees(list, parentId = 0) {
                let parentObj = {};
                list.forEach(o => {
                    parentObj[o.id] = o;
                })
                if (!parentId) {
                    return list.filter(o => !parentObj[o.parentId]).map(o =>
                        (o.children = this._getTrees(list, o.id), o)
                    );
                } else {
                    return list.filter(o => o.parentId === parentId).map(o => (o.children = this._getTrees(list, o.id), o));
                }
            },
            // 编辑操作
            handleEdit(index, row) {
                this.idx = index;
                this.form = row;
                this.editVisible = true;
            },
            // 保存编辑
            saveEdit() {
                this.editVisible = false;
                this.$message.success(`修改第 ${this.idx + 1} 行成功`);
                this.$set(this.tableData, this.idx, this.form);
            },
        }
    }
    ;
</script>

<style scoped>
    .category-wrapper {
        /*text-align: center;*/
        position: relative;
    }

    .category-wrapper .el-button {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 20;
    }

    .box-card {
        /*margin: 5px 0 15px;*/
        margin-bottom: 15px;
    }

    .el-card {
        border: 2px solid #EBEEF5;;
    }

    .el-card__body .el-image {
        width: 40% !important;
        max-height: 240px;
        overflow: hidden;
    }

    .el-card__body .title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 30px;
    }

    .flex-row .description {
        margin: 20px 40px;
        width: 45%;

    }

    /*上传图片处理*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        object-fit: cover;
        width: 100%;
        height: auto;
        display: block;
    }
</style>
